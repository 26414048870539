.Speaker {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    padding: var(--spacing-small);
    margin-top: var(--spacing-small-half);
    gap: var(--spacing-small);
    span {
        margin-top: 0;
        text-align: center;
    }

    p {
        padding: 0;
        margin: 0;
        @media (max-width: 600px) {
            font-size: var(--font-size-small);
        }
    }

}

.Image {
    position: relative;
    border-radius: var(--spacing-small);
    width: 125px;
    height: 125px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    margin-right: var(--spacing-small);
    @media (max-width: 500px) {
        display: none;
    }
    > img {
        border-radius: var(--spacing-small);
    }
}

.Company {
    img, svg {
        width: 125px;
    }
}

.Preview {
    cursor: pointer;
}