@use 'styles/events-button' as *;

.SessionBrowser{    
    background: var(--colour-background-secondary);
    > ul {
        list-style-type: none !important;
        margin: 0;
        padding: 0;
    }
}

.Theatres {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-small-half);
    margin-bottom: var(--spacing-small);
    > button {
        @include events-button;
        margin-top: 0;
        &[data-active='true'] {
            background: var(--colour-background-accent-tertiary);
        }
    }
}

.Columns {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-small);
    @media ( max-width: 1050px ) {
        flex-direction: column;
    }
    > section > h1 {
        text-align: center;
        color: var(--colour-background-accent-secondary);
        [data-days='1'] & {
            display: none;
        }
        margin: 0;
        padding: 0;
    }
}
.Sessions {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    [data-days='1'] & {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 1050px) {
            grid-template-columns: 1fr;
        }
    }
    gap: var(--spacing-small);
    list-style-type: none;
}