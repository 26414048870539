.SessionFeatured {
    display: flex;
    flex-direction: column;
    background: var(--colour-background-primary);
    padding: var(--spacing-small-half);
    ul {
        list-style-type: none;
        padding-left: 0;
    }
    h2 {
        font-size: var(--font-size-large);
        color: var(--colour-text-secondary);
        margin: 0;
    }
    h3 {
        font-size: var(--font-size-medium);
        color: var(--colour-background-accent-secondary);
        margin: 0;
    }
    p {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}